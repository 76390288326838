import * as React from "react";
import {Button, Container, Grid, Header, Segment} from "semantic-ui-react";
import "../../css/intro.css";
import Hero from '../pages/hero04';

import "animate.css/animate.compat.css";
import {navigateTo} from "../../helpers/router";
import {t} from "../../i18n";
import { StaticImage } from 'gatsby-plugin-image';
import LandingSection01 from "../parts/LandingSection01";
import LandingBackgroundSection05 from "../backgrounds/landings/Section05";
import LandingBackgroundSection02 from "../backgrounds/landings/Section02";
import LandingBackgroundSection03 from "../backgrounds/landings/Section03";
import LandingBackgroundSection04 from "../backgrounds/landings/Section04";
import IntroListItem from '../utils/IntroListItem';
import NewFooter from './newfooter';
import SEO from "../seo";

export default class Landing04 extends React.Component {
  render() {
    return (
      <Segment basic className="overall-segment">
        <SEO title="Suplementy dla sportowców" />
        <Hero/>
        <LandingSection01>
          <Header as={"h2"} id='intro-h2'>Dobre suplementy <b>dla sportowców</b>, którzy szukają <b>kompleksowego zestawu</b></Header>
          <p id={'section-01-01-lead'}>Regularnie uprawiasz sport, biegasz lub chodzisz na siłownię?</p>
          <p>Zatem z pewnością wiesz, że pewne makroelementy i witaminy dla sportowców mają ogromne znaczenie.</p>
          <p>Twoje mięśnie, stawy i kości potrzebują szeregu substancji odżywczych, aby prawidłowo funkcjonować, dawać Ci siłę i szybkość.</p>
          <p>Nasze suplementy dla sportowców nie ograniczają się do jednego, czy dwóch składników, a komponowane są tak, aby stworzyć pełnowartościowy zestaw, którego zadaniem jest uzupełnić Twoją codzienną dietę. Produkujemy suplementy diety od ponad 25 lat - możesz zaufać naszemu doświadczeniu.</p>
        </LandingSection01>

        <LandingBackgroundSection02>
          <Segment basic id="section-01-02">
            <Container id="section-01-02-container">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={8} verticalAlign="middle">
                    <Header as={"h2"} id='intro-h3'>Przyjemnie zaskoczysz się, że nasze <b>suplementy dla sportowców są personalizowane</b></Header>
                    <p>
                      Zapewne zastanawiasz się, jakie suplementy dla mężczyzn są dla Ciebie odpowiednie. Już nie musisz. Nasz zespół naukowy
                      opracował specjalną ankietę, dzięki której poznamy Twój wiek, wagę, wzrost, dietę i tryb życia, i na tej podstawie skomponujemy dla Ciebie Twój własny unikalny zestaw witamin i minerałów w
                      ilościach, jakich potrzebuje Twój organizm. Nie będą to tzw. suplementy na testosteron, choć nasz mix wielowitaminowy z minerałami zawiera też coś naturalnie wspomagającego i tę kwestię. Ma postać wygodnych saszetek do rozpuszczania w wodzie.
                    </p>
                  </Grid.Column>
                  <Grid.Column width={8} verticalAlign="middle">
                    <StaticImage src="../../img/landings/04/section_3.png" alt="Suplementy dla sportowców" placeholder="none"/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection02>

        <Segment basic style={{padding: "42px 0"}}>
          <Container>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <StaticImage src="../../img/landings/04/section_4.png" alt="Suplementy" placeholder="none"/>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <Header as={"h2"} id='intro-h3'>Zestaw witamin <b>dla sportowców</b> + makroelementy i ekstrakty z roślin</Header>
                  <p>
                    Suplementy diety dla sportowców w wygodnych saszetkach Pure4Me to nie tyle zwykła multiwitamina dla sportowców, ale zestaw substancji odżywczych, w których znaleźć się mogą
                    również pożyteczne dla sprawności fizycznej składniki mineralne oraz standaryzowane ekstrakty z roślin. Co ważne, nasza marka Pure4Me nie używa żadnych konserwantów i zbędnych wypełniaczy. Korzystając z nowoczesnych laboratoriów i
                    sprawdzonych receptur, pieczołowicie dbamy o wysoką jakość i przyswajalność wszystkich preparatów. Co więcej, saszetki słodzone są wyłącznie bezpiecznym ksylitolem i stewią (zero
                    cukru!). Nie musisz więc obawiać się, że w jakikolwiek niekorzystny sposób odbiją się na Twojej diecie. Zastanawiasz się, co mogą zawierać nasze suplementy dla sportowców? Oto przykładowe składniki.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <LandingBackgroundSection03>
          <Segment basic id="section-01-03">
            <Container id="section-01-03-container">
              <Grid stackable relaxed={"very"} centered>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/04/ico_1.png' alt="Wapń" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Wapń dla <b>mocnych kości</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <p>Wzmocnienie struktury kości i dbałość o ich zdrowie to podstawowa funkcja wapnia. Ten
                          niezwykle ważny dla osób uprawiającychsport makroelement zawiera jony, które
                          budują kości - to one zapewniają optymalną gęstość mineralną kośćca.</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/04/ico_2.png' alt="Magnez" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Magnez dla <b>sprawnych mięśni</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <p>Mało które suplementy dla sportowców nie
                            zawierają magnezu, ponieważ podczas treningów organizm ludzki wykorzystuje go w
                            dużych ilościach. Warto więc go regularnie uzupełniać. Magnez wspomaga sprawne
                            funkcjonowanie mięśni i przyczynia się do zmniejszenia uczucia zmęczenia.</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/04/ico_3.png' alt="Energia" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Skrzyp polny, pokrzywai żeń-szeń <b>dla energii</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <p>Te trzy rośliny doskonale ożywiają organizm.
                          Żeń-szeń dodatkowo przeciwdziała uczuciu zmęczenia. Ich suplementacja dla
                          sportowców może mieć zatem znaczenie przede wszystkim w okresach zwiększonego
                          wysiłku fizycznego, trudnych treningów i długich biegów.</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row/>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/04/ico_4.png' alt="Potas" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Potas dla nawodnienia <b>podczas treningu</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <p>
                            Potas wspomaga utrzymanie prawidłowego ciśnienia krwi i podobnie jak magnez wspiera
                            funkcjonowanie układu nerwowo-mięśniowego. Jest niezbędny do
                            zapewnienia odpowiedniego przewodnictwa impulsów nerwowych, a co więcej, odgrywa
                            ważną rolę w nawadnianiu komórek podczas treningu.
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/04/ico_5.png' alt="Kurkuma" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Kurkuma - suplement <b>na stawy dla sportowców</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <p>
                            Wykorzystywany w naszych suplementach
                            ekstrakt z kurkumy wspiera ruchomość stawów, co ma niebagatelne znaczenie
                            szczególnie dla biegaczy. Kurkuma jest również doskonale znana ze swoich silnych
                            właściwości przeciwzapalnych. Może być więc stosowana prewencyjnie, w celu zmniejszenia
                            niedogodności odczuwanych po intensywnych treningach.
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/04/ico_6.png' alt="Witamina D" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Witamina D <b>zmniejsza ryzyko kontuzji</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <p>
                            Rolę tej witaminy dla sportowców podkreśla nawet Narodowe Centrum Edukacji
                            Żywieniowej. Uważa się, że witamina D ogranicza ryzyko doznania kontuzji w wyniku
                            upadków (chroni kości przed łamaniem), a wyniki niektórych badań sugerują, że może
                            również korzystnie wpływać na wydolność tlenową i beztlenową organizmu.
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection03>

        <LandingBackgroundSection04>
          <Segment basic id="section-01-04">
            <Container id="section-01-04-container">

              <Grid stackable relaxed={'very'}>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Header as={'h2'} id='intro-h3'><b>Pure4me</b><br/> <b>kompleksowe suplementy dla sportowców</b> w wygodnych saszetkach do rozpuszczania w wodzie</Header>
                    <p> 
                      Każdy stworzony przez nas zestaw saszetek wystarczy Ci na 30 dni.
                      Znajdą się w nim zarówno witaminy i składniki mineralne dla sportowców, jak i wysokiej jakości, w pełni bezpieczne ekstrakty
                      roślinne, którą ożywiają ciało i dbają o stawy. Doskonale wiemy, że możesz mieć różne potrzeby w zależności od swojego wieku,
                      wzrostu i masy ciała, a także sposobu żywienia oraz intensywności wysiłku sportowego.
                    </p>
                    <p id={'section-01-01-lead'}>Przygotowane dla Ciebie suplementy diety dla sportowców mogą okazać się dla Ciebie korzystne, jeśli chcesz:</p>
                    <div>
                      <IntroListItem><b>zadbać o zdrowie</b> swoich kości i ograniczyć ryzyko złamań</IntroListItem>
                      <IntroListItem>zapewnić swoim stawom <b>optymalną mobilność</b></IntroListItem>
                      <IntroListItem><b>wesprzeć funkcjonowanie</b> swoich mięśni i układu nerwowego</IntroListItem>
                      <IntroListItem>mieć <b>więcej energii</b> na treningach i zmniejszyć uczucie zmęczenia</IntroListItem>
                      <IntroListItem><b>zapobiec nadmiernej utracie</b> wody przez organizm</IntroListItem>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8} verticalAlign="middle">
                    <StaticImage src='../../img/landings/04/section_6.png' alt='Pozytywy suplementacji' placeholder='none' />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection04>

        <LandingBackgroundSection05>
          <Segment basic id="section-01-05">
            <Container id="section-01-05-container">
              <Grid stackable relaxed={'very'}>
                <Grid.Column width={8} verticalAlign="middle">
                  <StaticImage src='../../img/landings/section_7.png' alt='Suplementy' placeholder='none'/>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as={"h2"} id='intro-h2'>Zamów suplementy dla sportowców <b>Pure4me</b></Header>
                  <p>
                    Jeśli chcesz sprawdzić, jakie suplementy dla sportowców możemy
                    Ci zaproponować, <b>wypełnij poniższą ankietę</b>. Jest bezpłatna i w żaden sposób nie zobowiązuje Cię do zakupu.
                    Jej wypełnienie potrwa <b>około 5 minut</b>. Tyle czasu pewnie uda Ci się znaleźć, żeby <b>zadbać o swoją kondycję i sprawność</b>.
                    Zwrotnie otrzymasz od nas propozycje suplementów, które następnie będziesz mógł nabyć przez stronę internetową <b>Pure4me</b>.
                  </p>
                  <p><b>Wesprzyj swoją sprawność fizyczną dzięki dobrej jakości składnikom odżywczym.</b></p>
                  <Grid>
                    <Button primary className="btn-primary shadow-light newintro-primary-button" id="btn-check"
                            onClick={() => navigateTo('/survey')}>
                      {t("sprawdź swój skład")}!
                    </Button>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection05>

        <span id="footer">
          <NewFooter/>
        </span>
      </Segment>
    );
  }
}