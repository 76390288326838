import * as React from "react"
import FullApp from "../FullApp";
import Layout from "../templates/layout";
import Landing04 from '../components/pages/landing04';

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <Landing04 />
    </Layout>
  </FullApp>
);