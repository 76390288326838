import * as React from "react";
import {
  Button, Container, Grid, Header, Segment
} from "semantic-ui-react";
import {t} from "../../i18n";
import 'animate.css/animate.css'
import {navigateTo} from "../../helpers/router";
import LandingBackgroundHero04 from '../backgrounds/landings/Hero04';

export default class Hero extends React.Component {
  render() {
    return (
      <LandingBackgroundHero04>
        <Segment basic id="landing-3-banner" className="intro-fullwidth-component">
          <Container>
            <Segment basic id='hero-container-01'>
              <Grid stackable>
                <Grid.Column computer={8} mobile={16} tablet={16}>
                  <Header as="h1" id='hero-title'>Suplementy diety <b>dla sportowców</b></Header>
                  <p id="hero-paragraph">Każdy z nas jest wyjątkowy i taki też jest <b>Pure4Me</b>. Suplement diety w 100% <b>personalizowany</b>, zawierający <b>witaminy i składniki mineralne dla sportowców</b>, a także <b>wspierające wysiłek fizyczny ekstrakty z roślin.</b></p>
                  <Grid>
                    <Button primary className="btn-primary shadow-light newintro-primary-button" id="btn-hero"
                            onClick={() => navigateTo('/survey')}>
                      {t("sprawdź swój skład")}!
                    </Button>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Segment>
          </Container>
        </Segment>
      </LandingBackgroundHero04>
    );
  }
}